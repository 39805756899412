
































































































































































































































































































































































































import Vue from 'vue';
import { AxiosResponse } from 'axios';
import priceCalculator from '@/service/price-calculator';
import { CASE_STATUS, WEBSITE_URL, WEBSITE_PUBLIC_URL } from '@/constant/constants';
import SERVICES from '@/config/services-list';
import { LUGGAGE, CARS_PHOTO, GLASS_PHOTO, SHOWS, TRANSPORT, SECURE, LOGISTIC } from '@/config/images';
import { getConditionsFilesHelper,
  getNextRouteNameByKeyToCustomerDataHelper,
  getProductNameForPricePageHelper } from '@/helper/functions';
import { getUser } from '@/helper/handle-auth';
import { AuthResponse } from '@/model/auth';

export default Vue.extend({
  name: 'price-card',
  computed: {
    isBARTTypeDL(): boolean {
      if (this.assesment.operatingMode !== undefined) {
        return (this.assesment.operatingMode['BART-Gruppe'] === 'DL');
      }
      return false;
    },
    user(): AuthResponse | void {
      return getUser();
    },
    roles() {
      const user: AuthResponse | void = getUser();
      if (user) return user.roles.split(',');
      return [];
    },
    isSuperAdmin(): boolean {
      return this.roles.indexOf('super-admin') > -1;
    },
    isAPIAdmin(): boolean {
      return this.roles.indexOf('APIAdmin') > -1;
    },
    isDisableAll(): boolean {
      if (this.isAPIObserver) {
        return true;
      }
      const result =
        this.caseStatus === CASE_STATUS.CONTRACT ||
        this.caseStatus === CASE_STATUS.SUGGESTION ||
        this.caseStatus === CASE_STATUS.CONTRACT_NO_SIGNATURE || this.AMSAPISendStatus !== null ||
        this.caseStatus === CASE_STATUS.OFFER_REQUEST_SEND;

      if (result) {
        this.disableAllInputs();
      }
      return result;
    },
    getProductName(): string {
      return getProductNameForPricePageHelper(this.insuranceKey);
    },
    getPriceAfterSplit(): number {
      switch (this.Zahlungsperiode) {
        case 'Halbjährlich':
          return this.price / 2;
        case 'Vierteljährlich':
          return this.price / 4;
        case 'Monatlich':
          return this.price / 12;
        default:
          return this.price;
      }
    },
    getSecondPriceAfterSplit(): number {
      switch (this.Zahlungsperiode) {
        case 'Halbjährlich':
          return this.secondPrice / 2;
        case 'Vierteljährlich':
          return this.secondPrice / 4;
        case 'Monatlich':
          return this.secondPrice / 12;
        default:
          return this.secondPrice;
      }
    },
    hasAlternativeProduct(): boolean {
      return (this.insuranceKey === 'FORWARDING_AGENT_INSURANCE') && (this.assesment.operatingMode['BART-Gruppe'] === 'HW');
      //   return this.isAgentInfo && this.assesment.operatingMode['BART-Gruppe'] !== 'HA/HE';
      // return (
      //   this.productId === 'c10b0299-e57f-4df7-82b9-f77c560f5bc3' ||
      //   this.productId === '77d9bf3b-d98f-4a88-8aa7-cf35ddd2c3cc'
      // );
    },
    showPrice(): boolean {
      const isShown =
        !this.assesment.haveBussiness ||
        !this.assesment.haveTransport ||
        this.assesment.remarks.length === 0 ||
        !this.insuranceData.FesteGebäude ||
        this.assesment.haveBussiness ||
        this.insuranceData.Gesamtumsatz > 5000000 ||
        this.insuranceData.maximumInsuredVehicle > 100000 ||
        this.insuranceData.ownVehicles === 'mehr als 10' ||
        this.insuranceData.Kunstgegenstände ||
        !this.insuranceData.exhibitionInPermanentBuilding;

      return isShown;
    },
    canGetPrice(): boolean {
      /* eslint operator-linebreak: [2, "after", { "overrides": { "?": "ignore", ":": "ignore"} }] */
      // console.log('ld', this.assesment.remarks ? this.assesment.remarks.length === 0 : false);
      const remarks = this.assesment.remarks ? this.assesment.remarks.length === 0 : true;
      const roadHaulierFormDataErrorPrice = this.formData.roadHaulierFormData ? this.formData.roadHaulierFormData.grossAnnual <= 2000000 : true;
      const roadHaulierFormDataVehicleFleetError = this.formData.roadHaulierFormData ? this.formData.roadHaulierFormData.VehicleFleet === false : true;
      // const forwardingActivitiesErrorPrice = this.formData.forwardingActivitiesFormData ? this.formData.forwardingActivitiesFormData.grossAnnual >= 2000000 : true;
      // const watchmanFormDataErrorPrice = this.formData.watchmanFormData ? this.formData.watchmanFormData.grossAnnual >= 2000000 : true;
      return (!this.assesment.haveBussiness && !this.assesment.haveTransport && remarks && roadHaulierFormDataErrorPrice && roadHaulierFormDataVehicleFleetError && (!this.$attrs.progressBar.forwardingActivities) && (!this.$attrs.progressBar.watchman));
    //
    },
  },
  props: [
    'assesment',
    'insuranceData',
    'priceCardData',
    'productId',
    'formData',
    'insuranceKey',
    'caseStatus',
    'backUrl',
    'priceApiData',
    'id',
    'manuallyPriceData',
    'isAPIObserver',
    'AMSAPISendStatus',
    'versionRK',
    'secondPriceVersionRK',
    'versionRKforOldType',
  ],
  watch: {
    id(value) {
      if (value !== null) {
        this.requestData();
      }
    },
  },
  data(): any {
    return {
      Laufzeit: 1,
      Zahlungsperiode: this.insuranceKey === 'SHORT_TERM_INSURANCE' ? 'Einmalig' : 'Jährlich',
      Selbstbeteiligung: 250,
      price: 0,
      factor: 0,
      loading: false,
      noPrice: false,
      unknownBArt: false,
      isAgentInfo: this.insuranceData.isAgentInfo,
      secondPrice: 0,
      calculation: null,
      calculationSecondProduct: null,
      RatingStatus: '',
      RatingStatusId: '',
      email: '',
      isSecondSelected: false,
      isSelected: !this.insuranceData.isAgentInfo,
      isDeductible: true,
      kurzfristig: false,
      services: [],
      useServices: false,
      useImage: false,
      imagePath: '',
      deductibleOptions: [],
      alternativeProductServices: ['Benannte Gefahren', 'Güter mit eigenen Fahrzeugen', 'Keine Fahrzeugnennung'],
      alternativeProductName: 'Werkverkehrsversicherung',
      manuallyPrice: 0,
      manuallySecondPrice: 0,
      manuallyFactor: 0,
      manuallySecondFactor: 0,
      showDownloadFiles: false,
      downloadFiles: [] as any,
      publicUrl: WEBSITE_PUBLIC_URL,
      WEBSITE_URL,
      pdf: '',
      fileName: 'Produktvergleich-Synopse-Warenversicherung-Werkverkehrsversicherung.pdf',
      WEBSITE_PUBLIC_URL,
      additionalPrice: [],
    };
  },
  methods: {
    goToBackUrl() {
      this.$router.push({
        name: this.backUrl,
      }).catch((err: any) => {
        throw new Error(`Problem handling something: ${err}.`);
      });
    },
    disableAllInputs() {
      const inputs = document.querySelectorAll('input');
      for (let i = 0; i < inputs.length; i += 1) {
        if (inputs[i].id !== 'alternative_product_manually_factor' && inputs[i].id !== 'alternative_product_manually_price' && inputs[i].id !== 'manually_factor' && inputs[i].id !== 'manually_price') {
          inputs[i].disabled = true;
        }
      }

      const selects = document.querySelectorAll('select');
      for (let i = 0; i < selects.length; i += 1) {
        selects[i].disabled = true;
      }
    },
    onSelectBtn() {
      this.isSecondSelected = false;
      this.isSelected = true;
      this.onNextClick(false);
    },
    onSecondBtn() {
      this.isSecondSelected = true;
      this.isSelected = false;
      this.onNextClick(false);
    },
    showPriceData() {
      this.noPrice = false;
      this.RatingStatus = this.$attrs.response.price_data.RatingStatus;
      this.RatingStatusId = this.$attrs.response.price_data.RatingStatusId;
      this.getPriceAndFactor(this.$attrs.response.price_data);
      if (this.hasAlternativeProduct) {
        this.secondPrice = this.$attrs.response.form_data.price_form_data.secondPrice;
        this.secondPriceAfterSplit = this.$attrs.response.form_data.price_form_data.secondPriceAfterSplit;
      }
    },
    requestData() {
      console.log('requestData');
      if (!this.canGetPrice) {
        this.noPrice = true;
        this.onNextClick(false);
        return;
      }
      if (this.isAgentInfo) {
        delete this.insuranceData.isAgentInfo;
      }
      let data;
      switch (this.insuranceKey) {
        case 'LUGGAGE_INSURANCE':
          // eslint-disable-next-line no-param-reassign
          this.priceApiData.Risiken.forEach((element: any) => { element.Laufzeit = this.Laufzeit; });
          data = {
            ...this.priceApiData,
            Zahlweise: this.Zahlungsperiode,
          };
          break;
        case 'FAIRGROUND_INSURANCE':
          // eslint-disable-next-line no-param-reassign
          this.priceApiData.Risiken.forEach((element: any) => { element.Selbstbehalt = this.Selbstbeteiligung; });
          data = {
            ...this.priceApiData,
            Zahlweise: this.Zahlungsperiode,
          };
          break;
        case 'LOGISTICS_INSURANCE':
          // eslint-disable-next-line no-param-reassign
          // this.priceApiData.Risiken.forEach((element: any) => { element.Selbstbehalt = this.Selbstbeteiligung; });
          data = {
            ...this.priceApiData,
            Laufzeit: this.Laufzeit,
            Zahlweise: this.Zahlungsperiode,
            Selbstbehalt: this.Selbstbeteiligung,
            Berechnungsmodus: 'Normal',
          };
          break;
        default:
          data = {
            ...this.priceApiData,
            Laufzeit: this.Laufzeit,
            Zahlweise: this.Zahlungsperiode,
            Selbstbehalt: this.Selbstbeteiligung,
          };
          break;
      }
      if (!this.isDisableAll) {
        this.loading = true;
        priceCalculator
          .calculate(data, this.productId, this.versionRK)
          .then((response: AxiosResponse) => {
            // console.log(response.data.status);
            this.calculation = response.data;
            if (response.data.Result) {
              this.noPrice = false;
              this.RatingStatus = response.data.RatingStatus;
              this.RatingStatusId = response.data.RatingStatusId;
              this.getPriceAndFactor(response.data);
              if (this.formData.roadHaulierFormData !== null && this.formData.roadHaulierFormData !== undefined) {
                if (this.formData.roadHaulierFormData.foreignUnitsPromoted) {
                  this.getAdditionalPrice();
                }
              }
            } else {
              this.noPrice = true;
              // this.Zahlungsperiode = '';
            }
          }).catch((e) => {
            this.noPrice = true;
            if (e.response.data.Message.indexOf('Unknown BArt') !== -1) {
              this.unknownBArt = true;
              // console.log('Unknown BArt');
            }
            // console.log(`Error - ${e}`);
          })
          .finally(() => {
            this.onNextClick(false);
            this.loading = false;
          });
      }
      if (this.hasAlternativeProduct) {
        let secondProduct;
        if (data.BetriebsartHandwerker) {
          secondProduct = {
            ...data,
            BetriebsartWerkverkehr: data.BetriebsartHandwerker,
            // BetriebsartHandwerker: data.BetriebsartWerkverkehr,
          };
        } else if (data.BetriebsartWerkverkehr) {
          secondProduct = {
            ...data,
            // BetriebsartWerkverkehr: data.BetriebsartHandwerker,
            BetriebsartHandwerker: data.BetriebsartWerkverkehr,
          };
        }
        let productId = '77d9bf3b-d98f-4a88-8aa7-cf35ddd2c3cc';
        if (this.insuranceKey === 'OWN_GOODS_INSURANCE') {
          productId = 'c10b0299-e57f-4df7-82b9-f77c560f5bc3';
        } else if (this.insuranceKey === 'FAIRGROUND_INSURANCE') {
          productId = '03603ad4-f35b-425a-8f87-bce69a4f5700';
        }
        // delete secondProduct.BetriebsartHandwerker;

        if (!this.isDisableAll) {
          priceCalculator
            .calculate(secondProduct, productId, this.secondPriceVersionRK)
            .then((response: AxiosResponse) => {
              this.calculationSecondProduct = response.data;
              if (response.data.Result && response.data.Result.Nettojahrespraemie) {
                this.secondPrice = response.data.Result.Nettojahrespraemie;
              } else {
                this.noPrice = true;
                // this.Zahlungsperiode = '';
              }
            })
            .finally(() => {
              this.onNextClick(false);
              this.loading = false;
            });
        }
      }
    },
    getAdditionalPrice() {
      const data = {
        ...this.priceApiData,
        Laufzeit: this.Laufzeit,
        Zahlweise: this.Zahlungsperiode,
        Selbstbehalt: this.Selbstbeteiligung,
        Berechnungsmodus: 'FremdeEinheiten',
      };
      priceCalculator
        .calculate(data, this.productId, this.secondPriceVersionRK)
        .then((response: AxiosResponse) => {
          this.additionalPrice = response.data;
          if (response.data.Result) {
            // console.log(response.data.Result.Praemie);
            this.price += response.data.Result.Praemie;
          } else {
            this.noPrice = true;
            // this.Zahlungsperiode = '';
          }
        })
        .finally(() => {
          this.onNextClick(false);
          this.loading = false;
        });
    },
    sendShortXML(id: number) {
      priceCalculator.sendShortXML(id)
        .then((response) => {
          console.log(response.data.message.response);
        });
    },
    getPriceAndFactor(data: any) {
      switch (this.insuranceKey) {
        case 'FORWARDING_AGENT_INSURANCE':
          this.price = data.Result.Nettojahrespraemie;
          this.factor = 0;
          break;
        case 'OWN_GOODS_INSURANCE':
          this.price = data.Result.Nettojahrespraemie;
          this.factor = 0;
          break;
        case 'ANNUAL_INSURANCE':
          this.price = data.Result.Nettojahrespraemie;
          // this.price = data.Result.Praemie;
          this.factor = data.Result.Faktor;
          break;
        case 'SHORT_TERM_INSURANCE':
          this.price = data.Result.Nettojahrespraemie;
          // this.price = data.Result.Praemie;
          this.factor = data.Result.Faktor;
          break;
        case 'SAMPLE_COLLECTION_INSURANCE':
          this.price = data.Result.Nettojahrespraemie;
          // this.price = data.Result.Praemie;
          this.factor = data.Result.Faktor;
          break;
        case 'FAIRGROUND_INSURANCE':
          this.price = data.Result.Nettojahrespraemie;
          // this.price = data.Result.Praemie;
          this.factor = 0;
          break;
        case 'LUGGAGE_INSURANCE':
          this.price = data.Result.Nettojahrespraemie;
          // this.price = data.Result.Praemie;
          this.factor = 0;
          break;
        case 'MARKET_CONCEPT_INSURANCE':
          this.price = data.Result.Nettojahrespraemie;
          this.factor = 0;
          break;
        case 'VALOR_INSURANCE_COMPACT':
          this.price = data.Result.Nettojahrespraemie;
          this.factor = 0;
          break;
        case 'LOGISTICS_INSURANCE':
          this.price = data.Result.Nettojahrespraemie;
          this.factor = data.Result.Faktor;
          break;
        default:
          this.price = 0;
          this.factor = 0;
          break;
      }
    },
    manuallyPriceUpdate() {
      this.loading = true;
      this.noPrice = false;
      priceCalculator.manuallyPriceUpdate({
        id: this.id,
        manuallyPrice: {
          Price: this.manuallyPrice,
          Factor: this.manuallyFactor,
        },
        status: CASE_STATUS.OFFER_REQUEST_READY,
      }).then(() => {
        this.$emit('manuallyPriceUpdate');
      }).finally(() => {
        this.loading = false;
      });
    },
    secondProductManuallyPriceUpdate() {
      this.noPrice = false;
      this.loading = true;
      priceCalculator.secondProductManuallyPriceUpdate({
        id: this.id,
        manuallyPrice: {
          Price: this.manuallySecondPrice,
          Factor: this.manuallySecondFactor,
        },
        status: CASE_STATUS.OFFER_REQUEST_READY,
      }).then(() => {
        this.$emit('manuallyPriceUpdate');
      }).finally(() => {
        this.loading = false;
      });
    },
    async onGeneratePdfClick() {
      // const id = this.id;
      this.onNextClick(false);
      // const pubLink = this.WEBSITE_PUBLIC_URL;
      priceCalculator.generatePDF(this.id).then((response) => {
        window.open(`${WEBSITE_URL}generate-pdf/${this.id}`);
        // const pdfName = response.data.price_card_pdf.filename;
        // window.open(`${WEBSITE_PUBLIC_URL}files/pdf/${pdfName}`);
        // window.open(`${WEBSITE_PUBLIC_URL}files/pdf/${pdfName}`);
      });
    },
    onNextClick(redirectToNextPage = true) {
      let priceData = this.calculation;
      let priceInsurance = this.price;
      if (this.isSecondSelected) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        priceData = this.calculationSecondProduct;
        priceInsurance = priceData.Result.Nettojahrespraemie;
      }
      /* eslint-disable @typescript-eslint/camelcase */
      const data = {
        insurance_type: this.insuranceKey,
        price_data: priceData,
        form_data: this.formData,
        RatingStatus: this.RatingStatus,
        RatingStatusId: this.RatingStatusId,
        price_form_data: {
          price: priceInsurance,
          Laufzeit: this.Laufzeit,
          Selbstbeteiligung: this.Selbstbeteiligung,
          Zahlungsperiode: this.Zahlungsperiode,
          priceAfterSplit: this.getPriceAfterSplit,
          email: this.email,
          noPrice: this.noPrice,
          secondPrice: this.secondPrice,
          secondPriceAfterSplit: this.getSecondPriceAfterSplit,
          hasAlternativeProduct: this.hasAlternativeProduct,
          isSecondSelected: this.isSecondSelected,
          manuallyPrice: this.manuallyPrice,
          manuallyFactor: this.manuallyFactor,
          manuallySecondPrice: this.manuallySecondPrice,
          manuallySecondFactor: this.manuallySecondFactor,
          additionalPrice: this.additionalPrice,
          versionRK: this.versionRK,
          secondPriceVersionRK: this.secondPriceVersionRK,
          versionRKforOldType: this.versionRKforOldType,
        },
      };
      this.$emit('price-card-data', data, priceInsurance, this.noPrice);

      if (redirectToNextPage) {
        this.sendShortXML(this.id);
        const routeName = getNextRouteNameByKeyToCustomerDataHelper(this.insuranceKey);
        this.$router.push({
          name: routeName,
          params: {
            id: this.$route.params.id,
          },
        }).catch((err: any) => {
          throw new Error(`Problem handling something: ${err}.`);
        });
      }
    },
    initInsurance() {
      switch (this.insuranceKey) {
        case 'LUGGAGE_INSURANCE':
          this.Laufzeit = 3;
          this.isDeductible = false;
          this.useServices = false;
          this.useImage = true;
          this.imagePath = LUGGAGE;
          this.Selbstbeteiligung = null;
          break;
        case 'FORWARDING_AGENT_INSURANCE':
          this.isDeductible = true;
          this.deductibleOptions = [
            {
              value: 0, field_text: '0 €',
            },
            {
              value: 250, field_text: '250 €',
            },
            {
              value: 500, field_text: '500 €',
            },
            {
              value: 1000, field_text: '1000 €',
            },
          ];
          this.Laufzeit = 3;
          this.Selbstbeteiligung = 250;
          if (!this.hasAlternativeProduct) {
            this.useServices = false;
            this.useImage = true;
            this.imagePath = TRANSPORT;
          } else {
            this.useServices = true;
            this.useImage = false;
            this.services = SERVICES.FORWARDING_AGENT_INSURANCE;
          }
          break;
        case 'OWN_GOODS_INSURANCE':
          this.isDeductible = true;
          this.deductibleOptions = [
            {
              value: 0, field_text: '0 €',
            },
            {
              value: 250, field_text: '250 €',
            },
            {
              value: 500, field_text: '500 €',
            },
            {
              value: 1000, field_text: '1000 €',
            },
          ];
          this.Selbstbeteiligung = 250;
          this.Laufzeit = 3;
          this.useServices = true;
          this.useImage = false;
          this.services = SERVICES.OWN_GOODS_INSURANCE;
          if (this.hasAlternativeProduct) {
            this.alternativeProductName = 'Warenversicherung';
            this.alternativeProductServices = ['Allgefahrendeckung', 'Transporte per Spedition, Frachtführer, Paketdienste, eigenen Fahrzeugen', 'Werkverkehr', 'Ausstellungen', 'Unabhängig der Gefahrtragung', 'Durchgehend von Haus zu Haus'];
          }
          break;
        case 'ANNUAL_INSURANCE':
          this.Laufzeit = 3;
          this.isDeductible = true;
          this.deductibleOptions = [
            {
              value: 0, field_text: '0 €',
            },
            {
              value: 250, field_text: '250 €',
            },
            {
              value: 500, field_text: '500 €',
            },
            {
              value: 1000, field_text: '1000 €',
            },
          ];
          this.Selbstbeteiligung = 250;
          this.useServices = false;
          this.useImage = true;
          this.imagePath = CARS_PHOTO;
          break;
        case 'SHORT_TERM_INSURANCE':
          this.isDeductible = true;
          this.kurzfristig = true;
          this.deductibleOptions = [
            {
              value: 0, field_text: '0 €',
            },
            {
              value: 250, field_text: '250 €',
            },
            {
              value: 500, field_text: '500 €',
            },
            {
              value: 1000, field_text: '1000 €',
            },
          ];
          this.Selbstbeteiligung = 250;
          this.useServices = false;
          this.useImage = true;
          this.imagePath = CARS_PHOTO;
          break;
        case 'SAMPLE_COLLECTION_INSURANCE':
          this.Laufzeit = 3;
          this.isDeductible = true;
          this.deductibleOptions = [
            {
              value: 0, field_text: '0 €',
            },
            {
              value: 250, field_text: '250 €',
            },
            {
              value: 500, field_text: '500 €',
            },
            {
              value: 1000, field_text: '1000 €',
            },
          ];
          this.Selbstbeteiligung = 250;
          this.useServices = false;
          this.useImage = true;
          this.imagePath = GLASS_PHOTO;
          break;
        case 'FAIRGROUND_INSURANCE':
          this.isDeductible = true;
          this.deductibleOptions = [
            {
              value: 'Klein', field_text: '150 €',
            },
            {
              value: 'Medium', field_text: '5% mind. 250 €, max. 2.500 €',
            },
            {
              value: 'Gross', field_text: '10% mind. 500 €, max 5.000 €',
            },
          ];
          this.Selbstbeteiligung = 'Klein';
          this.useServices = false;
          this.useImage = true;
          this.imagePath = SHOWS;
          break;
        case 'VALOR_INSURANCE_COMPACT':
          this.Laufzeit = 3;
          this.isDeductible = false;
          // this.kurzfristig = true;
          this.deductibleOptions = [
            {
              value: 0, field_text: '0 €',
            },
            {
              value: 250, field_text: '250 €',
            },
            {
              value: 500, field_text: '500 €',
            },
            {
              value: 1000, field_text: '1000 €',
            },
          ];
          this.Selbstbeteiligung = null;
          this.useServices = false;
          this.useImage = true;
          this.imagePath = SECURE;
          break;
        case 'LOGISTICS_INSURANCE':
          this.Laufzeit = 3;
          this.isDeductible = false;
          this.useServices = false;
          this.Selbstbeteiligung = null;
          this.useImage = true;
          this.imagePath = LOGISTIC;
          break;
        default:
          this.isDeductible = true;
          this.deductibleOptions = [
            {
              value: 0, field_text: '0 €',
            },
            {
              value: 250, field_text: '250 €',
            },
            {
              value: 500, field_text: '500 €',
            },
            {
              value: 1000, field_text: '1000 €',
            },
          ];
          this.Selbstbeteiligung = 250;
          this.useServices = true;
          this.useImage = false;
          this.services = ['Allgefahrendeckung', 'Transporte per Spedition, Frachtführer, Paketdienste', 'Werkverkehr', 'Ausstellungen', 'Unabhängig der Gefahrtragung', 'Durchgehend von Haus zu Haus'];
          break;
      }
      if (this.formData.price_form_data) {
        this.Laufzeit = this.formData.price_form_data.Laufzeit;
        this.Selbstbeteiligung = this.formData.price_form_data.Selbstbeteiligung;
        this.Zahlungsperiode = this.formData.price_form_data.Zahlungsperiode;
        this.email = this.formData.price_form_data.email;
      }
      this.manuallyPrice = this.manuallyPriceData.defaultProduct ? this.manuallyPriceData.defaultProduct.Price : 0;
      this.manuallyFactor = this.manuallyPriceData.defaultProduct ? this.manuallyPriceData.defaultProduct.Factor : 0;
      this.manuallySecondPrice = this.manuallyPriceData.secondProduct ? this.manuallyPriceData.secondProduct.Price : 0;
      this.manuallySecondFactor = this.manuallyPriceData.secondProduct ? this.manuallyPriceData.secondProduct.Factor : 0;
    },
  },

  mounted() {
    if (!this.insuranceData) {
      this.$router.push('/transport-insurance');
      return;
    }
    if (this.formData !== undefined && this.insuranceKey === 'FORWARDING_AGENT_INSURANCE') {
      if (this.formData.price_form_data === undefined) {
        this.isSecondSelected = false;
      } else {
        this.isSecondSelected = (this.formData.price_form_data.isSecondSelected !== undefined ? this.formData.price_form_data.isSecondSelected : false);
      }
      this.isSelected = !this.isSecondSelected;
    }
    this.initInsurance();
    if (this.isDisableAll) {
      this.disableAllInputs();
      this.showPriceData();
    }
    if (this.manuallyPrice || this.manuallySecondPrice) {
      this.price = this.manuallyPrice + this.manuallySecondPrice;
    }
    this.downloadFiles = getConditionsFilesHelper(this.insuranceKey);
    if (this.id !== null) {
      this.requestData();
    }
  },
});
